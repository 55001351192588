<template>
  <vs-card>
    <vs-row>
      <vs-col vs-type="flex" vs-align="left" vs-w="12">
        <div class="w-full m-5">
          <h3>Update Brand</h3>
        </div>
        <div class="w-full m-5" align="right">
          <vs-button
            :disabled="!validateForm"
            @click="editData"
            icon-pack="feather"
            class="mr-2"
            >Save</vs-button
          >
          <vs-button @click="cancelHandler" type="border"
            >Cancel</vs-button
          >
        </div>
      </vs-col>
    </vs-row>
    <vs-divider />
    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
        <div class="w-full sm:w-1/2 m-5">
          <vs-input
            :success="!errors.first('basic.name') && data.name != ''"
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('basic.name') ? true : false"
            v-validate="'required'"
            name="name"
            data-vv-as="name"
            label="Brand Name"
            class="w-full"
            data-vv-scope="basic"
            :danger-text="errors.first('basic.name')"
            v-model="data.brandName"
          />
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full sm:w-1/2 m-5">
            <vs-input
              :success="!errors.first('basic.slug') && data.slug != ''"
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('basic.slug') ? true : false"
              v-validate="'required'"
              name="slug"
              data-vv-as="slug"
              label="Brand Slug"
              class="w-full"
              data-vv-scope="basic"
              :danger-text="errors.first('basic.slug')"
              v-model="data.slug"
              placeholder="Example-slug"
            />
          </div>
        </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
        <div class="w-full m-5">
          <label class="vs-input--label">Brand Description</label>
          <vue-ckeditor v-model="data.description" :config="config"/>
        </div>
      </vs-col>
    </vs-row>
    <vs-divider />
    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12" class="h-[500px]">
        <div class="m-5">
          <p class="text-2xl pb-2"><strong>Brand Image</strong> - Optional</p>
          <p class="text-sm">When uploading a brand images, please upload both a mobile and desktop image to 
            <br/> ensure that the experience in store is accurate across all device types. </p>
        </div>
        <vs-col
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          vs-w="12"
          class="vs-con-loading__container flex flex-col"
        >
          <div class="flex flex-col gap-3 items-start justify-between m-5 w-full h-full">
            <label class="text-base font-semibold">Desktop Image 1280x220</label>
            <div v-if="data.desktopImage" class="flex flex-col items-start gap-2">
              <img :src="data.desktopImage" alt="Uploaded" />
              <vs-button line-origin="center" color="dark" type="line" class="px-0 py-1 ml-1" @click="handleRemoveImage('desktop')">Remove Image</vs-button>
            </div>
            <div v-else id="desktop" class="customHeight w-3/4 sm:w-1/2 text-center relative">
              <div
                class="border-2 border-dashed border-gray-500 p-5 
                  h-full w-full absolute flex flex-col gap-3 
                  items-center justify-center"
                @dragover.prevent
                @drop="handleDrop($event, 'desktop')"
              >
                <feather-icon icon="UploadIcon" class="text-primary2" />
                <p class="text-primary2 text-xl">Drop files to upload</p>
                <p class="text-primary2 text-lg">or</p>
                <input type="file" ref="fileInputDesktop" hidden @change="handleFileChange($event, 'desktop')" accept="image/*" />
                <vs-button @click="uploadDesktopImage" class="bg-primary2 w-1/2" :key="1">Select Files</vs-button>
                <p class="text-lg font-light">Recommended Image Size</p>
                <p class="text-lg font-light">1280 x 220</p>
              </div>
            </div>
          </div>
          <div class="flex flex-col gap-3 items-start justify-between m-5 w-full h-full">
            <label class="text-base font-semibold">Mobile Image 360x220</label>
            <div v-if="data.mobileImage" class="flex flex-col items-start gap-2">
              <img :src="data.mobileImage" alt class="img-fluid" />
              <vs-button line-origin="center" color="dark" type="line" class="px-0 py-1 ml-1" @click="handleRemoveImage('mobile')">Remove Image</vs-button>
            </div>
            <div v-else id="mobile" class="customHeight w-3/4 sm:w-1/2 text-center relative">
              <div
                class="border-2 border-dashed border-gray-500 p-5 
                  h-full w-full absolute flex flex-col gap-3 
                  items-center justify-center"
                @dragover.prevent
                @drop="handleDrop($event, 'mobile')"
              >
                <feather-icon icon="UploadIcon" class="text-primary2" />
                <p class="text-primary2 text-xl">Drop files to upload</p>
                <p class="text-primary2 text-lg">or</p>
                <input type="file" ref="fileInputMobile" hidden @change="handleFileChange($event, 'mobile')" accept="image/*" />
                <vs-button @click="uploadMobileImage" class="bg-primary2 w-1/2" :key="2">Select Files</vs-button>
                <p class="text-lg font-light">Recommended Image Size</p>
                <p class="text-lg font-light">360 x 220</p>
              </div>
            </div>
          </div>
        </vs-col>
    </vs-row>
    <vs-divider />
    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <div class="m-5">
          <p class="text-2xl pb-2 font-bold">Brand Settings</p>
      </div>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
        <div class="w-full m-5 mt-0">
          <label class="vs-input--label text-primary2 font-semibold">Custom Page</label>
          <vs-switch v-model="data.hasCustomPage" class="mt-2"></vs-switch>
          
          <div v-if="data.hasCustomPage" class="pt-6">
            <vs-dropdown vs-trigger-click>
              <vs-button class="mb-4">Add Section</vs-button>
              <vs-dropdown-menu>
                <vs-dropdown-item @click="addModule('banner')"> Banner </vs-dropdown-item>
                <vs-dropdown-item @click="addModule('text')"> Text </vs-dropdown-item>
                <vs-dropdown-item @click="addModule('carousel')"> Carousel </vs-dropdown-item>
                <vs-dropdown-item @click="addModule('video')"> Video </vs-dropdown-item>
                <vs-dropdown-item @click="addModule('product')"> Products </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
<!-- Dynamic Form Container start -->
            <draggable
              v-model="JSONData"
              @start="dragging = true"
              @end="dragging = false"
              v-bind="dragOptions"
            >
            <transition-group type="transition" name="flip-list">

              <div v-for="(component, index) in JSONData" :key="index">
                <bannerList
                  v-if="component.module === 'banner'"
                  :component="component"
                  :JSONData="JSONData"
                  :moduleIndex="index"
                  @removeComponent="removeComponent"
                  :isDragging="isDragging"
                  @isBannerFormValid="handleBannerFormValidation"
                  ref="bannerComponent"
                />
                <carousel
                  v-if="component.module === 'carousel'"
                  :component="component"
                  :JSONData="JSONData"
                  :moduleIndex="index"
                  @removeComponent="removeComponent"
                  :isDragging="isDragging"

                />
                <textForm
                  v-if="component.module === 'text'"
                  :component="component"
                  :JSONData="JSONData"
                  :index="index"
                  @removeComponent="removeComponent"
                  @handleTextOnchange="handleTextOnchange"
                  :isDragging="isDragging"
                />
                <videoForm
                  v-if="component.module === 'video'"
                  :component="component"
                  :JSONData="JSONData"
                  :index="index"
                  @handleVideoTitleChange="handleVideoTitleChange"
                  @handleVideoDescriptionChange="handleVideoDescriptionChange"
                  @handleVideoSourceChange="handleVideoSourceChange"
                  @removeComponent="removeComponent"
                  :isDragging="isDragging"

                />

                <productsForm
                  v-if="component.module === 'products'"
                  :component="component"
                  :JSONData="JSONData"
                  :index="index"
                  @removeComponent="removeComponent"
                  @handleProductsTitleChange="handleProductsTitleChange"
                  @handleSelectProduct="handleSelectProduct"
                  :isDragging="isDragging"

                />
              </div>
            </transition-group>
            </draggable>
<!-- Dynamic Form Container end -->
<div class="w-full flex justify-end">

<vs-button @click="saveCustomPage" class=" mr-3">
  Save Custom Page
</vs-button>
<vs-button @click="customPreview">
  Custom page preview
</vs-button>
</div>
          </div>

        </div>
      </vs-col>
    </vs-row>
  </vs-card>
</template>

<script>
import { mapActions } from "vuex";
import axios from "@/axios";
import VueCkeditor from 'vue-ckeditor2';
import textForm from "../brands/custom-page-forms/text.vue";
import videoForm from "../brands/custom-page-forms/video.vue";
import bannerList from "../brands/custom-page-forms/bannerList.vue";
import carousel from "../brands/custom-page-forms/carousel.vue";
import productsForm from "../brands/custom-page-forms/products.vue";
import draggable from "vuedraggable";


export default {
  name: "BrandEdit",
  components: {
    VueCkeditor,
    textForm,
    videoForm,
    bannerList,
    carousel,
    productsForm,
    draggable,
  },
  props: {
    brandId: {
      type: String,
    },
    slugAlreadyExists: {
      type: Boolean,
    },
  },
  data() {
    return {
      id: this.brandId,
      data: {},
      config: {
        toolbar: [
          {
            name: 'clipboard',
            items: ['Undo', 'Redo']
          },
          { name: 'styles', items: ['Format'] },
          {
            name: 'basicstyles',
            items: [
              'Bold',
              'Italic',
              'Strike',
            ]
          },
          {
            name: 'pagragraph',
            items: [
              'NumberedList',
              'BulletedList',
            ]
          },
          { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
        ],
        versionCheck: false,
        height: 300
      },
      dragging: false,
      imageType: '',
      desktopImageUrl: '',
      mobileImageUrl: '',
      JSONData: [],
      textJson: {
        module: 'text',
        html: '',
      },
      videoJson: {
        module: 'video',
        title: '',
        description: '',
        source: '',
      },
      productJson: {
        module: 'products',
        title: "",
        elements: [],
      },
      bannerJson: {
          module: 'banner',
          elements: [
            {
            title: '',
            description: '',
            cta_title: '',
            cta_link: '',
            image: '',
            image_mobile: ''
          }
          ]
        },
        carouselJson: {
          module: 'carousel',
          elements: [
            {
            cta: '',
            image: '',
            image_mobile: ''
          }
          ]
        },
        isBannerValid: [],
    };
  },
  computed: {
    validateForm() {
      return (
        !this.errors.any()
      );
    },
    isDragging() {

      return this.dragging ? true : false;
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  methods: {
    ...mapActions("brand", ["getBrandDetails"]),
    ...mapActions("eCommerce", ["saveBrandJsonSettings", "getBrandJsonSettings", "uploadMedia"]),
    handleRemoveImage(type) {
      if (type === "desktop") {
        this.data.desktopImage = "";
      } else {
        this.data.mobileImage = "";
      }
    },
    handleDrop(event, type) {
      event.preventDefault();
      this.imageType = type;
      this.uploadImage(event.dataTransfer.files[0]);
    },
    handleFileChange(event, type) {
      this.imageType = type;
      this.uploadImage(event.target.files[0]);
    },
    uploadDesktopImage() {
      this.$refs.fileInputDesktop.click();
    },
    uploadMobileImage() {
      this.$refs.fileInputMobile.click();
    },
    fetchBrandDetails(id) {
      this.$vs.loading();
      this.getBrandDetails(id).then((res) => {
        this.data = res.data.data;
        this.$vs.loading.close();
      });
    },
    uploadImage(event, key = "profileImage") {
      this.$vs.loading({
        text: "Uploading...",
        color: "#3dc9b3",
        background: "white",
        container: "#" + this.imageType,
        scale: 0.6,
      });
      let data = new FormData();
      data.append("profileImage", event);
      let config = {
        header: {
          "Content-Type": "image/png",
        },
      };
      axios
        .post("/api/v1/doctors/upload?key=" + key, data, config)
        .then((res) => {
          if (this.imageType === "desktop") {
            this.data.desktopImage = res.data.Location;
            this.desktopImageUrl = res.data.Location;
          } else if (this.imageType === "mobile") {
            this.data.mobileImage = res.data.Location;
            this.mobileImageUrl = res.data.Location;
          }
          this.$vs.loading.close("#" + this.imageType + " > .con-vs-loading");
        })
        .catch((err) => {
          this.$vs.loading.close("#" + this.imageType + " > .con-vs-loading");
          if (err && err.status === 415) {
            this.$vs.notify({
              title: "Upload Failed",
              text: "File Format Not Supported",
              color: "danger",
            });
          } else {
            this.$vs.notify({
              title: "Upload Failed",
              text: "Please try again later.",
              color: "danger",
            });
          }
        });
        
    },
    async editData() {
      let isValid = await this.$validator.validateAll("basic");
      if (!isValid) {
        this.$vs.notify({
          title: "Error",
          text: "Validation Failed",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
        return false;
      }
      this.$emit("editDetail", this.data);
    },
    cancelHandler() {
      this.$emit("cancelEdit");
    },
    customPreview() {
      const slug = this.data.slug
      if(slug !== '' || !isUndefined(slug) ){
        const routeData = this.$router.resolve({
          name: "SuperAdminCustomBrandPreviewPage",
          params: {
              slug: slug
          },
          query: {
              page: 1
          }
        })
        window.open(routeData.href, '_blank');
      }
    },
    saveCustomPage() {
      this.$vs.loading({
        text: "Saving...",
        color: "#3dc9b3",
      });
      this.isBannerValid = []
      this.validateBannerForm()
      if (this.isBannerValid.includes(false)) {
        setTimeout(() => {
          this.$vs.loading.close();
        }, 200);
        this.$vs.notify({
          title: "Error",
          text: "Banner Image is required.",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
        return

      }
      const value = JSON.parse(JSON.stringify(this.JSONData));
      value.forEach((value)=>{
        if(value.module === "text")
            value.html = value.html.replace(/style="/g, '_style="');
      })
      const brandId = this.brandId;
      this.saveBrandJsonSettings({ value, brandId })
        .then(()=> {
          this.$vs.notify({
          title: "Success!",
          text: "The custom brand page has been saved successfully.",
          color: "success",
        });
        this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.notify({
            title: "Failed to save json settings.",
            text: "Please try again later.",
            color: "danger",
          });
          this.$vs.loading.close();
          console.error(err);
          return false
        });
    },
    addModule(module) {
      const textObject = {...this.textJson};
      const videoObject = {...this.videoJson};
      const productObject = {...this.productJson};
      const bannerObject = JSON.parse(JSON.stringify(this.bannerJson));
      const carouselObject = JSON.parse(JSON.stringify(this.carouselJson));
      switch (module) {
        case "text":
          this.JSONData.push(textObject) 
          break;
        case "banner":
          this.JSONData.push(bannerObject) 
          break;
          case "carousel":
            this.JSONData.push(carouselObject) 
            break;
        case "video":
          this.JSONData.push(videoObject) 
          break;
        case "product":
          this.JSONData.push(productObject) 
          break;
      
        default:
          break;
      }
    },
    removeComponent(index) {
      this.JSONData.splice(index, 1)
    },
    handleTextOnchange(value,index){
      this.JSONData[index].html = value
    },
    handleVideoTitleChange(value,index){
      this.JSONData[index].title = value
    },
    handleVideoDescriptionChange(value,index){
      this.JSONData[index].description = value
    },
    handleVideoSourceChange(value,index){
      this.JSONData[index].source = value
    },
    handleProductsTitleChange(value,index){
      this.JSONData[index].title = value
    },
    handleSelectProduct(value,index){
      this.JSONData[index].elements = value
    },
    handleBannerFormValidation(value) {
      this.isBannerValid.push(value);
    },
    async validateBannerForm() {
      if (this.$refs.bannerComponent) {
        this.$refs.bannerComponent.forEach(component => {
          if (component.handleValidateBannerForm) {
            component.handleValidateBannerForm();
          }
        });
      }
    },
  },
  async created() {
    this.id = this.brandId;
    await this.fetchBrandDetails(this.id);
    this.getBrandJsonSettings({ brandId: this.brandId }).then((val) => {
      if(val.data.data === null){
        this.JSONData = []
        return null
      }
      this.JSONData = val.data.data;
    });
  },
  watch: {
    'data.slug': function() {
      this.$emit("updateSlugAlreadyExists")
    }
  },
};
</script>

<style scoped>
.customHeight {
  height: 30dvh;
}
.ghost {
  opacity: 0.5;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
</style>
